import { Injectable } from '@angular/core';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { buildApiUrl } from '../../utils/helper';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { routes } from '../../routes/routes';
import { MessagingType } from '../../models/messaging/messaging-type.enum';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {

  fcmToken: string;
  private msg: Messaging | null = null;

  constructor(private http: HttpClient,
              private router: Router,
              private alertService: AlertService) { }

  init(messaging: Messaging) {
    if (Capacitor.isNativePlatform && Capacitor.getPlatform() !== 'web') {
      console.log('Native platform messaging');
      this.initMobileNotifications();
    } else {
      if (this.msg) {
        this.msg = messaging;
        console.log('Web platform messaging');
        this.initWebNotifications();
      } else {
        console.log('Web platform messaging not init');
      }
    }
  }

  async deleteToken() {
    // We can also delete fcm tokens, make sure to also update this on your firestore db if you are storing them as well
    // await deleteToken(this.msg);
    // Sync firestore
    if (this.fcmToken) {
      console.log('Deleting token from server', this.fcmToken);
      this.http.delete(buildApiUrl('signup/token'), { body: { token: this.fcmToken } }).pipe(take(1))
        .subscribe(
          (res) => {
            console.log('Token successfully deleted', res);
          },
          (error) => {
            console.error('Error deleting token from server:', error);
          }
        );
    }
  }

  private initMobileNotifications() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        console.log('token value and persist token', token.value);
        // send token to server
        this.fcmToken = token.value;
        // this.persistsToken(token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        console.error('Error on PushNotifications registration:', error);
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (data: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(data));
        this.alertService.info(data.title, data.body);
        console.log('Push received: ', data.title);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        // Should perform action based on notification data type
        console.log('Notification tapped ! :', notification, notification?.notification?.data?.type);
        const bookingId = notification?.notification?.data?.bookingId;
        switch(notification?.notification?.data?.type) {
          case MessagingType.BOOKING_CREATED:
            // Check user type and navigate to the correct page
            console.log('Booking created with id:', bookingId);
            this.router.navigate([routes.bookingDetails], {queryParams: { id: bookingId }});
            break;
          case MessagingType.BOOKING_UPDATED_PROVIDER:
            console.log('Booking updated provider with id:', bookingId);
            this.router.navigate([routes.bookingDetails], {queryParams: { id: bookingId }});
            break;
          case MessagingType.BOOKING_UPDATED_CUSTOMER:
            console.log('Booking updated customer with id:', bookingId);
            this.router.navigate([routes.bookingDetails], {queryParams: { id: bookingId }});
            break;
          case MessagingType.SERVICE_OWNER_APPROVED_CONTRACTOR:
            console.log('Service owner approved contractor with id:', bookingId);
            this.router.navigate([routes.providerServices]);
            break;
          default:
            break;
        }
      }
    );
  }

  private initWebNotifications() {
    Notification.requestPermission().then(
      (notificationPermissions: NotificationPermission) => {
        if (notificationPermissions === 'granted') {
          console.log('Granted');
        }
        if (notificationPermissions === 'denied') {
          console.log('Denied');
        }
      }, () => {
        console.warn('Error requesting notification permissions');
      });
    navigator.serviceWorker
      .register('/assets/firebase-messaging-sw.js', {
        type: 'module',
      })
      .then((serviceWorkerRegistration) => {
        getToken(this.msg, {
          vapidKey: environment.vapidKey,
          serviceWorkerRegistration: serviceWorkerRegistration,
        }).then((token: string) => {
          console.log('my fcm token', token);
          // This is a good place to then store it on your database for each user
          this.fcmToken = token;
          // this.persistsToken(token);
        });
      }, (error) => {
        console.error('Error registering service worker:', error);
      });

    onMessage(this.msg, (msg) => {
      console.log('My Firebase Cloud Message', msg);
      if (msg.notification.title !== 'Test' && msg.notification.body !== 'Validating your token...') {
        this.alertService.info(msg.notification.title, msg.notification.body);
      }
    });

  }

  persistsToken(value: string = this.fcmToken) {
    if (value) {
      setTimeout(() => {
        this.http.post(buildApiUrl('signup/token'), {
          token: value,
          platform: Capacitor.getPlatform()
        }).pipe(take(1))
          .subscribe(
            (res) => {
              console.log('Token successfully registered', res);
            },
            (error) => {
              console.error('Error sending token to server:', error);
            }
          );
      }, 500);
    }
  }

}
