import { Injectable, Injector } from '@angular/core';
import { collection, doc, getFirestore, onSnapshot } from '@angular/fire/firestore';
import { from, lastValueFrom, Observable, take, throwError } from 'rxjs';
import { BaseComponent } from '../../../shared/component/base/base.component';
import { Router } from '@angular/router';
import { routes } from '../../../shared/routes/routes';
import { UserStore } from '../../../shared/stores/user/user.store';
import { HttpClient } from '@angular/common/http';
import { buildApiUrl } from '../../../shared/utils/helper';
import { SubscriptionsPlanService } from '../../../shared/services/subscriptions-plan/subscriptions-plan.service';
import { MessagingService } from '../../../shared/services/messaging/messaging.service';
import { NotificationsService } from '../../../shared/services/notifications/notifications.service';
import {
  FirebaseAuthentication,
  Persistence as CapacitorPersistence,
  User as CapacitorUser
} from '@capacitor-firebase/authentication';
import { GetCurrentUserResult, SignInResult } from '@capacitor-firebase/authentication/dist/esm/definitions';
import {
  Auth,
  EmailAuthProvider,
  GoogleAuthProvider,
  IdTokenResult,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
  User, createUserWithEmailAndPassword, sendEmailVerification,
} from '@angular/fire/auth';
import { usersPath } from '../../../shared/config/paths';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { IHoliday } from '../../../shared/models/calendar/holiday.interface';
import { AlertService } from '../../../shared/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseComponent {

  protected readonly routes = routes;

  isLoggedIn = false;
  justSignedUp = false;
  hasLoggedIn = false;

  thirdPartyProviderCredentials: CapacitorUser | null;

  redirectParams: {
    redirect: string,
    id: string,
  };

  private lastUser = null;
  private token: string | undefined;
  private translateService!: TranslateService;  // Declare but do not inject
  private verificationEmailInterval: any;

  constructor(private userStore: UserStore,
              private http: HttpClient,
              private auth: Auth,
              private alertService: AlertService,
              private messagingService: MessagingService,
              private injector: Injector,
              private notificationsService: NotificationsService,
              private subscriptionPlanService: SubscriptionsPlanService,
              private router: Router) {
    super();
    // needed for plugin
    initializeApp(environment.firebaseConfig);

    this.auth.onAuthStateChanged((user) => {
      this.lastUser = user; // Update the last user
      this.subscribeToUserState(user);
    });

    this.auth.onIdTokenChanged(async (user) => {
      if (user && this.lastUser?.uid !== user.uid) {
        this.lastUser = user; // Update the last user
        await user.reload(); // Refresh user data (important for email verification updates)
        this.subscribeToUserState(user);
      }
    });
  }

  refreshUserToken(): Observable<string> {
    return new Observable<string>((observer) => {
      this.auth.currentUser?.getIdToken(true)
        .then((token: string) => {
          // console.log('User token refreshed', token);
          this.token = token;
          this.subscribeToUserState(this.auth.currentUser);
          observer.next(token);
          observer.complete();
        })
        .catch((error) => {
          console.error('Token refresh failed', error);
          observer.error(error);
        });
    });
  }

  async getUserRole(): Promise<IdTokenResult> {
    return this.auth.currentUser?.getIdTokenResult();
  }

  getUserToken(): string | undefined {
    return this.token;
  }

  getUserUUID(): string | undefined {
    return this.auth.currentUser?.uid;
  }

  createUserFromThirdPartyProvider() {
    if (this.thirdPartyProviderCredentials) {
      this.createUser({
        uid: this.thirdPartyProviderCredentials.uid,
        email: this.thirdPartyProviderCredentials.email,
        displayName: this.thirdPartyProviderCredentials.displayName,
        photoURL: this.thirdPartyProviderCredentials.photoUrl,
        phoneNumber: this.thirdPartyProviderCredentials.phoneNumber
      });
    }
  }

  async signInWithEmailAndPassword(email: any, password: any, rememberMe: boolean) {
    await this.setPersistence(rememberMe);
    this.hasLoggedIn = true;
    await FirebaseAuthentication.signInWithEmailAndPassword({email: email.trim(), password: password.trim()});
    await signInWithEmailAndPassword(this.auth, email.trim(), password.trim());
  }

  async setPersistence(rememberMe: boolean) {
    try {
      if (Capacitor.getPlatform() === 'web') {
        if (rememberMe) {
          await FirebaseAuthentication.setPersistence({persistence: CapacitorPersistence.IndexedDbLocal});
        } else {
          await FirebaseAuthentication.setPersistence({persistence: CapacitorPersistence.BrowserSession});
        }
      }
    } catch(e) {
      console.error('setPersistence error', e);
    }
  }

  async googleSignIn(): Promise<any> {
    const data: SignInResult = await FirebaseAuthentication.signInWithGoogle({mode: 'popup', scopes: ['email', 'profile']});
    const credentials = GoogleAuthProvider.credential(data.credential?.idToken);
    await signInWithCredential(this.auth, credentials);
    this.thirdPartyProviderCredentials = data.user;
    const additionalUserInfo = data.additionalUserInfo;
    if (additionalUserInfo?.isNewUser) {
      this.justSignedUp = true;
      this.hasLoggedIn = true;
      this.createUserFromThirdPartyProvider();
    } else {
      this.hasLoggedIn = true;
      this.subscribeToUserState(this.auth.currentUser);
    }
  }

  async signUpWithEmailAndPassword(email: string, password: any, phone: any, name: string, rememberMe: boolean): Promise<any> {
    await this.setPersistence(rememberMe);
    // @ts-ignore
    return createUserWithEmailAndPassword(this.auth, email, password).then((data: UserCredential) => {
      this.justSignedUp = true;
      this.sendEmailVerification();
      // FirebaseAuthentication.sendEmailVerification();
      return this.createUser({
        uid: data.user.uid,
        email: data.user.email,
        displayName: name,
        photoURL: data.user.photoURL,
        phoneNumber: phone,
        providerId: data.user.providerId,
      });
    });
  }

  async signOut() {
    this.router.navigate([routes.home]);
    this.messagingService?.deleteToken();
    await FirebaseAuthentication.signOut();
    await signOut(this.auth);
  }

  async createUser(userData: any): Promise<any> {
    const user = {
      uid: userData.uid,
      email: userData.email,
      displayName: userData.displayName,
      photoURL: userData.photoURL,
      phoneNumber: userData.phoneNumber,
    };
    const newUser = { user };
    try {
      const response = await lastValueFrom(this.http.post(buildApiUrl('signup'), newUser).pipe(take(1)));
      await lastValueFrom(this.refreshUserToken().pipe(take(1))); // Refresh token after successful user creation
      return response;
    } catch (error) {
      console.error('Error creating user signup', error);
      throw error; // Reject the promise with the error
    }
  }

  forgotPassword(email: string): void {
    sendPasswordResetEmail(this.auth, email).then(() => {
      console.log('password reset email successfully sent');
    });
  }

  updateUserSelectedLanguage(language: string) {
    this.userStore.update({
      selectedLanguage: language,
    });
  }

  changePassword(currentPassword: string, newPassword: string) {
    // Convert the promise to an observable using fromPromise (or from)
    return from(FirebaseAuthentication.getCurrentUser().then((data: GetCurrentUserResult) => data.user)).pipe(
      switchMap((user) => {
        if (user && user.email) {
          // Re-authenticate the user with their current password
          const credential = EmailAuthProvider.credential(user.email, currentPassword);

          // Convert the promise-based re-authentication into an observable
          return from(reauthenticateWithCredential(this.auth.currentUser, credential)).pipe(
            // Switch to the password update once re-authentication is done
            switchMap(() => from(FirebaseAuthentication.updatePassword({ newPassword })).pipe(
              switchMap(() => {
                console.log('Password changed successfully');
                return from([{ success: true, message: 'Password changed successfully' }]);
              }),
              switchMap(() => throwError({ success: false, message: 'Error changing password' }))
            ))
          );
        } else {
          console.error('No authenticated user found.');
          return throwError({ success: false, message: 'No authenticated user found' });
        }
      })
    );
  }

  sendEmailVerification() {
    if (!this.translateService) {
      this.translateService = this.injector.get(TranslateService);
    }
    sendEmailVerification(this.auth.currentUser).then(() => {
      this.alertService.success(this.translateService.instant('shared.verify-email-alert-success'));
      this.startVerificationEmailInterval();
    }, () => {
      this.alertService.error(this.translateService.instant('shared.verify-email-alert-error'));
    });
    // FirebaseAuthentication.sendEmailVerification().then(() => {
    //   console.log('email verification sent');
    // });
  }

  startVerificationEmailInterval() {
    if (!this.verificationEmailInterval) {
      this.verificationEmailInterval = setInterval(() => {
        this.auth.currentUser?.reload().then(() => {
          if (this.auth.currentUser?.emailVerified) {
            clearInterval(this.verificationEmailInterval);
            this.verificationEmailInterval = null;
            this.alertService.success(this.translateService.instant('shared.verify-email-alert-verified'));
          }
        });
      }, 5000)
    }

  }

  toggleAutoAppointments() {
    return this.http.put(buildApiUrl(`providers/${this.userStore.getValue().uid}/autoAppointments`), { value: this.userStore.getValue().autoAppointments });
  }

  private async subscribeToUserState(user: User) {
    // handle user state changes here. Note, that user will be null if there is no currently logged in user.
    if (user) {
      this.notificationsService.fetchNotifications(this.getUserUUID());
      user.getIdTokenResult().then((tokenResult: IdTokenResult) => {
        this.token = tokenResult.token;
        if (this.hasLoggedIn) {
          this.messagingService?.persistsToken();
        }
        this.isLoggedIn = true;
        this.getUser(collection(getFirestore(), usersPath), user, routes.providerDashboard);
        if (this.redirectParams?.redirect) {
          this.router.navigate([this.redirectParams.redirect || routes.home], {queryParams: {id: this.redirectParams.id}});
        } else if (this.hasLoggedIn) {
          this.router.navigate([routes.home]);
        }
        this.hasLoggedIn = false;
      });
    } else {
      this.userStore.reset();
    }
  }

  private async getUser(collection: any, user: User, route: string) {
    const converter = {
      toFirestore: (data: any) => {
        return data;
      },
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        if (data.calendar) {
          data.calendar = {
            holidays: data.calendar?.holidays?.map((holiday: IHoliday) => {
              // start and end are timestamp firebase. Should convert them to date
              return Object.assign({}, holiday, {
                start: holiday.start.toDate(),
                end: holiday.end.toDate(),
              });
            })
          };
        }
        if (data.schedule) {
          data.schedule = {
            Monday: data.schedule?.Monday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Tuesday: data.schedule?.Tuesday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Wednesday: data.schedule?.Wednesday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Thursday: data.schedule?.Thursday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Friday: data.schedule?.Friday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Saturday: data.schedule?.Saturday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
            Sunday: data.schedule?.Sunday?.map((item: any) => {
              return {
                ...item,
                from: item.from.toDate(),
                to: item.to.toDate(),
              };
            }),
          }
        }
        if (data.createdAt) {
          data.createdAt = data.createdAt.toDate();
        }
        return data;
      },
    }
    onSnapshot(doc(collection, user.uid).withConverter(converter), (document) => {
      if (document.exists()) {
        const data = document.data();
        // console.log('Document data:', data);
        this.userStore.update(Object.assign({}, {
          autoAppointments: data.autoAppointments || false,
          bio: data.bio || '',
          birthdate: data.birthdate || null,
          bookingInterval: data.bookingInterval || null,
          calendar: data.calendar || null,
          createdAt: data.createdAt || null,
          displayName: data.displayName || '',
          email: data.email || '',
          entityType: data.entityType || '',
          expertise: data.expertise || '',
          gender: data.gender || '',
          isProfileCompleted: data.isProfileCompleted,
          languages: data.languages || [],
          location: data.location || null,
          name: data.name,
          phoneNumber: data.phoneNumber || '',
          schedule: data.schedule || null,
          selectedLanguage: data.selectedLanguage || 'en',
          socialProfiles: data.socialProfiles || null,
          uid: data.uid || null,
          photoURL: `${data.photoURL}`,
          updatedAt: data.updatedAt || null,
          vatNumber: data.vat || '',
          website: data.website || '',
          isLoggedIn: true,
          emailVerified: user.emailVerified,
        }));
        // this.fetchProviderAdditionalInformation();
        // this.subscriptionPlanService.fetchSubscriptionsPlanForProvider();
        if (this.justSignedUp) {
          this.justSignedUp = false;
          this.router.navigate([route]);
        }
      } else {
        console.log('No such document in collection', collection.path);
      }
    });
  }

  // async facebookSignIn(type?: UserType): Promise<any> {
  //   console.log('facebookSignIn new');
  //   const data = await FirebaseAuthentication.signInWithFacebook({ mode: 'redirect', scopes: ['email', 'public_profile'] });
  //   console.log('facebookSignIn data', data);
  //   const credentials = FacebookAuthProvider.credential(data.credential?.accessToken);
  //   await signInWithCredential(this.auth, credentials);
  //   console.log('facebook sign in', data);
  //   this.thirdPartyProviderCredentials = data.user;
  //   const additionalUserInfo = data.additionalUserInfo;
  //   if (additionalUserInfo?.isNewUser) {
  //     console.log('facebook sign in new user');
  //     this.justSignedUp = true;
  //     if (type) {
  //       console.log('creating user from facebook', type);
  //       this.hasLoggedIn = true;
  //       this.createUserFromThirdPartyProvider();
  //     } else {
  //       console.log('redirecting to choose signup');
  //       this.router.navigate([routes.chooseSignup], {queryParams: {googleSignup: 'true'}});
  //     }
  //   } else {
  //     console.log('facebook sign in existing user');
  //     this.hasLoggedIn = true;
  //     this.router.navigate([routes.home]);
  //   }
  // }
}
